import React, { useContext, useEffect, useRef, useState } from "react";
import { MediaAuthContext } from "../../store/context/media-auth-context";
import OgImg from "../../assets/images/raster/components/common/mainscreen.png";
import PG_JP_CONTENT from "../../components/jp/pro/index.content";
import { PAGES } from "../../helpers/constants";
import Layout from "../../layouts/LayoutJp";
import Seo from "../../components/Seo";
import HomeIntro from "../../components/jp/pro/HomeIntro";
import ProInstall from "../../components/jp/pro/ProInstall";
import WhyAmzscout from "../../components/jp/pro/WhyAmzscout";
import UsingAmzscout from "../../components/jp/pro/UsingAmzscout";
import Profits from "../../components/jp/pro/Profits";
import CustomizableContainer from "../../containers/Paywalls/Customizable/CustomizableContainer";
import PartnerBrand from "../../components/common/PartnerBrand";
import JpChromeRating from "../../components/common/JpChromeRating";
import JpRatingReview from "../../components/common/JpRatingReview/";
import JpStandardFormBlockContainer from "../../containers/common/forms/JpStandardFormBlockContainer";
import "../../components/jp/pro/index.scss";

const PgJP = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const [isShowYoutubeModal, setIsShowYoutubeModal] = useState(false);
  const [urlYoutubeModal, setUrlYoutubeModal] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const tariffs = useRef(null);
  const { postData } = PG_JP_CONTENT;
  const closeYoutubeModal = () => setUrlYoutubeModal("");

  useEffect(() => {
    if (window.location.hash) {
      setIsScrolled(
        window.location.hash.substring(1).split("?")[0] === "pricing"
      );
    }
  }, []);

  useEffect(() => {
    setIsShowYoutubeModal(!!urlYoutubeModal);
  }, [urlYoutubeModal]);

  if (isScrolled) {
    tariffs.current.scrollIntoView({ block: "start", behavior: "smooth" });
    setIsScrolled(false);
  }

  return (
    <Layout
      tariffsElement={tariffs}
      closeYoutubeModal={closeYoutubeModal}
      urlYoutubeModal={urlYoutubeModal}
      isShowYoutubeModal={isShowYoutubeModal}
      customClass="PgJP"
      // isShowLanguageSwitcher
    >
      <Seo
        title="AMZscout PRO拡張機能 - 無料でお試しください"
        description="強力なAMZScoutPRO拡張機能の使用を通じて、ブラウザですべてのAmazon商品の販売量、価格履歴、競争スコア、およびその他の統計にアクセスできます。無料試用を開始してください。"
        page="jp/pro"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro />
      <JpChromeRating />
      <PartnerBrand />
      <JpRatingReview
        trustText="素晴らしい"
        trustSubText="Trustpilotのレビューに基づく"
        posts={postData}
      />
      <UsingAmzscout />
      <JpStandardFormBlockContainer
        buttonTextFirstState="無料でPRO拡張機能をトライ"
        buttonTextSecondState="無料でPRO拡張機能をトライ"
        page={PAGES.JP_PRO}
        customClass={
          isAuth
            ? "PgJP-standardFormPageBlock-secondState"
            : "PgJP-standardFormPageBlock-firstForm"
        }
        title="月額5,000ドル以上の収益を生む製品を見つける！"
        subTitle="PRO 拡張機能の無料トライアルで "
      />
      <CustomizableContainer pricingRef={tariffs} language="ja" subTitlePro />
      <ProInstall />
      <Profits />
      <WhyAmzscout />
      <JpStandardFormBlockContainer
        buttonTextFirstState="無料でPRO拡張機能をトライ"
        buttonTextSecondState="無料でPRO拡張機能をトライ"
        page={PAGES.JP_PRO}
        customClass={
          isAuth
            ? "PgJP-standardFormPageBlock-secondState"
            : "PgJP-standardFormPageBlock-secondForm"
        }
        title="メールアドレスを入力して、無料トライアルを開始していただけます。"
      />
    </Layout>
  );
};

export default PgJP;
