import React from "react";
import TrackedLink from "../../../Analytics/TrackedLink";
import LinkCopyIcon from "../../../../assets/images/vector/components/common/link_copy.svg";
import PG_JP_PROINSTALL_CONTENT from "./index.content";
import "./index.scss";

const ProInstall = () => {
  const { itemsDataList } = PG_JP_PROINSTALL_CONTENT;

  return (
    <section className="PgJP-ProInstall">
      <div className="container">
        <h2 className="PgJP-ProInstall__title">
          AMZScout PRO拡張機能を3クリックでインストールしてください！
        </h2>
        <p className="PgJP-ProInstall__subtitle">
          Amazonの製品に関する詳細な分析をリアルタイムで手に入れよう
        </p>
        <div className="PgJP-ProInstall__list">
          {itemsDataList.map(({ pic, text }, i) => (
            <div key={i} className="PgJP-ProInstall__list-item">
              <picture>
                <source
                  type="image/webp"
                  media="(min-width: 1366px)"
                  srcSet={`${pic.webp} 1x, ${pic.webp2x} 2x`}
                />
                <source
                  media="(min-width: 1366px)"
                  srcSet={`${pic.pic} 1x, ${pic.pic2x} 2x`}
                />
                <source
                  type="image/webp"
                  media="(min-width: 768px) and (max-width: 1365px)"
                  srcSet={`${pic.webpTab} 1x, ${pic.webpTab2x} 2x`}
                />
                <source
                  media="(min-width: 768px) and (max-width: 1365px)"
                  srcSet={`${pic.picTab} 1x, ${pic.picTab2x} 2x`}
                />
                <source
                  type="image/webp"
                  media="(max-width: 767px)"
                  srcSet={`${pic.webpMob} 1x, ${pic.webpMob2x} 2x`}
                />
                <source
                  media="(max-width: 767px)"
                  srcSet={`${pic.picMob} 1x, ${pic.picMob2x} 2x`}
                />
                <img
                  src={pic.pic}
                  alt=""
                  loading="lazy"
                  className="PgJP-ProInstall__list-img"
                />
              </picture>
              <p className="PgJP-ProInstall__list-num">{`0${i + 1}.`}</p>
              <p className="PgJP-ProInstall__list-text">{text}</p>
              {i === 0 && (
                <TrackedLink
                  category="Index"
                  action="CTA"
                  label="PRO Extension for Chrome"
                  class="PgJP-ProInstall__list-copyLink"
                  path="https://chrome.google.com/webstore/detail/amzscout-pro/njopapoodmifmcogpingplfphojnfeea"
                  target
                >
                  ここをクリック
                  <img src={LinkCopyIcon} alt="img" loading="lazy" />
                </TrackedLink>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProInstall;
